import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: window.SENTRY_DSN,
  environment: window.SENTRY_ENV,
  ignoreErrors: [
    'ResizeObserver loop',
    /Could not load "stats"\.$/,
    'Error: Could not load "util"',
    'Error: Promise timed out',
    /Loading chunk \d+ failed(?:\n|.)+https:\/\/js-agent\.newrelic\.com/,
  ],
});

window.Sentry = Sentry;
